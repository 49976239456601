import { createApp, defineAsyncComponent } from 'vue'
// import store from './store'
//import webStore from './store/web'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { initForms } from './scripts/forms'
import '../src/styles/tailwind.css'

import { VueRestrictedInputDirective } from 'vue-restricted-input'
// import VueSocialSharing from 'vue-social-sharing'

// Vue.use(VueSocialSharing)
// Vue.use(VueRestrictedInput)

// Vue.config.productionTip = false
// Vue.config.devtools = true

if (window.jQuery) {
  window.jQuery(function () {
    initForms(window.jQuery)
  })
}

var images = document.querySelectorAll('.imageload img')
function imagesLoaded(element) {
  element.parentNode.parentNode.querySelector('.bg-overlay-gradient').classList.remove('hidden')
}
if (images.length > 0) {
  for (var i = 0; i < images.length; i++) {
    if (images[i].complete) {
      imagesLoaded(images[i])
    } else {
      images[i].addEventListener('load', imagesLoaded(images[i]))
    }
  }
}

//set --vh based on inneHeight
function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
}
window.addEventListener('resize', appHeight)
appHeight()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Vue.component('EllevioWebLoginArea', () => import('./stories/web/vue-components/LoginArea.vue'))
// Vue.component('EllevioWebSearchTopArea', () => import('./stories/web/vue-components/SearchTopArea.vue'))
// Vue.component('EllevioWebSearchInput', () => import('./stories/web/vue-components/SearchInput.vue'))
// Vue.component('EllevioWebEventListener', () => import('./stories/web/vue-components/EventListener.vue'))

document.querySelectorAll('.vue-header-area').forEach((singleComponent) => {
  createApp({})
    // .use(webStore)
    .component(
      'EllevioWebHeaderArea',
      defineAsyncComponent(() => import('./stories/web/vue-components/HeaderArea.vue'))
    )
    .component(
      'EllevioWebLoginArea',
      defineAsyncComponent(() => import('./stories/web/vue-components/LoginArea.vue'))
    )
    .component(
      'EllevioWebSearchTopArea',
      defineAsyncComponent(() => import('./stories/web/vue-components/SearchTopArea.vue'))
    )

    .mount(singleComponent)
})

document.querySelectorAll('.vue-header').forEach((singleComponent) => {
  createApp({})
    .use(pinia)
    // .use(webStore)
    .component(
      'EllevioWebHeader',
      defineAsyncComponent(() => import('./stories/web/vue-components/Header.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-scroll-trigger-list').forEach((singleComponent) => {
  createApp({})
    // .use(webStore)
    .component(
      'EllevioWebScrollTriggerList',
      defineAsyncComponent(() => import('./stories/web/vue-components/ScrollTriggerList.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-mobile-navigation').forEach((singleComponent) => {
  createApp({})
    .use(pinia)
    //.use(store)
    .component(
      'EllevioWebMobileNavigation',
      defineAsyncComponent(() => import('./stories/web/vue-components/MobileNavigation.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-media-carousel').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'ElWebMediaCarousel',
      defineAsyncComponent(() => import('./stories/web/vue-components/MediaCarousel.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-project-map-container').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebProjectMapContainer',
      defineAsyncComponent(() => import('./stories/web/vue-components/ProjectMapContainer.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-scroll-down').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebScrollDown',
      defineAsyncComponent(() => import('./stories/web/vue-components/ScrollDown.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-ellevio-web-energy-guidance').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebEnergyGuidance',
      defineAsyncComponent(() => import('./stories/web/vue-components/EnergyGuidance.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-two-column-video').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebTwoColumnVideoBlock',
      defineAsyncComponent(() => import('./stories/web/vue-components/TwoColumnVideoBlock.vue'))
    )
    .component(
      'EllevioWebTwoColumnVideoBlockWithSlot',
      defineAsyncComponent(() => import('./stories/web/vue-components/TwoColumnVideoBlockWithSlot.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-button-list').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebButtonList',
      defineAsyncComponent(() => import('./stories/web/vue-components/ButtonList.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-rounded-card-image-item').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebRoundedCardImageItem',
      defineAsyncComponent(() => import('./stories/web/vue-components/RoundedCardImageItem.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.wysiwyg table').forEach((table, index) => {
  const componentRoot = document.createElement('div')

  table.setAttribute('id', 'index-' + index)
  table.parentNode.insertBefore(componentRoot, table.nextSibling)

  createApp({
    template: `<ellevio-web-table :tableIndex="${index}" />`
  })
    //.use(store)
    .component(
      'EllevioWebTable',
      defineAsyncComponent(() => import('./stories/web/vue-components/Table.vue'))
    )
    .mount(componentRoot)
})

document.querySelectorAll('.vue-overview-info').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebOverviewInfo',
      defineAsyncComponent(() => import('./stories/my-pages/vue-components/OverviewInfo.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-feedback-form').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebFeedbackForm',
      defineAsyncComponent(() => import('./stories/web/vue-components/FeedbackForm.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-search-input').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebSearchInput',
      defineAsyncComponent(() => import('./stories/web/vue-components/SearchInput.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-search-result-input').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebSearchResultInput',
      defineAsyncComponent(() => import('./stories/web/vue-components/SearchResultInput.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-accordion-single').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebAccordionSingle',
      defineAsyncComponent(() => import('./stories/web/vue-components/AccordionSingle.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-accordion-item').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebAccordionItem',
      defineAsyncComponent(() => import('./stories/web/vue-components/AccordionItem.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-accordion-block').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebAccordion',
      defineAsyncComponent(() => import('./stories/web/vue-components/Accordion.vue'))
    )
    .component(
      'EllevioWebShowMoreAccordions',
      defineAsyncComponent(() => import('./stories/web/vue-components/ShowMoreAccordions.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-faq-list').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebFaqList',
      defineAsyncComponent(() => import('./stories/web/vue-components/FaqList.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-news-room').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebNewsRoom',
      defineAsyncComponent(() => import('./stories/web/vue-components/NewsRoom.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-show-more-items').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebShowMoreItems',
      defineAsyncComponent(() => import('./stories/web/vue-components/ShowMoreItems.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-show-more-filtered-items').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebShowMoreFilteredItems',
      defineAsyncComponent(() => import('./stories/web/vue-components/ShowMoreFilteredItems.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-alert').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebAlert',
      defineAsyncComponent(() => import('./stories/web/vue-components/Alert.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-contact-us-form').forEach((singleComponent) => {
  createApp({})
    .directive('mask', VueRestrictedInputDirective)
    .component(
      'EllevioWebContactUsForm',
      defineAsyncComponent(() => import('./stories/web/vue-components/ContactUsForm.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-create-account-form').forEach((singleComponent) => {
  createApp({})
    .directive('mask', VueRestrictedInputDirective)
    .component(
      'EllevioWebCreateAccountForm',
      defineAsyncComponent(() => import('./stories/web/vue-components/CreateAccountForm.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-scroll-behaviour').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebScrollBehaviour',
      defineAsyncComponent(() => import('./stories/web/vue-components/ScrollBehaviour.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-share-on-social-media').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebShareOnSocialMedia',
      defineAsyncComponent(() => import('./stories/web/vue-components/ShareOnSocialMedia.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-modal-logged-out').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebModalLoggedOut',
      defineAsyncComponent(() => import('./stories/web/vue-components/ModalLoggedOut.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.EPiServerForms .Form__Element__Phone').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebEpiserverPhoneNumber',
      defineAsyncComponent(() => import('./stories/web/vue-components/EpiserverPhoneNumber.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-reachme-subscription-block').forEach((singleComponent) => {
  createApp({})
    //.use(store)
    .component(
      'EllevioWebReachmeSubscriptionBlock',
      defineAsyncComponent(() => import('./stories/web/vue-components/ReachmeSubscriptionBlock.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-accordion-project').forEach((singleComponent) => {
  createApp({})
    .component(
      'EllevioWebAccordionProject',
      defineAsyncComponent(() => import('./stories/web/vue-components/AccordionProject.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-updates-project').forEach((singleComponent) => {
  createApp({})
    .component(
      'EllevioWebUpdatesProject',
      defineAsyncComponent(() => import('./stories/web/vue-components/UpdatesProject.vue'))
    )
    .mount(singleComponent)
})

document.querySelectorAll('.vue-modal').forEach((singleComponent) => {
  createApp({})
    .component(
      'EllevioWebModal',
      defineAsyncComponent(() => import('./stories/web/vue-components/Modal.vue'))
    )
    .mount(singleComponent)
})

setTimeout(function () {
  document.querySelectorAll('.wysiwyg span[data-type="tooltip"]').forEach((btn) => {
    const componentRoot = document.createElement('div')
    const title = btn.getAttribute('data-title')
    const content = btn.getAttribute('data-content')

    btn.parentNode.insertBefore(componentRoot, btn.nextSibling)

    createApp({
      template: `<ellevio-web-tooltip title="${title}" content="${content}" />`
    })
      //.use(store)
      .component(
        'EllevioWebTooltip',
        defineAsyncComponent(() => import('./stories/web/vue-components/Tooltip.vue'))
      )
      .mount(componentRoot)
  })
}, 0)

if (document.querySelector('.vue-my-pages')) {
  //const pinia = createPinia()
  //pinia.use(piniaPluginPersistedstate)

  createApp({})
    .use(pinia)
    // .use(store)
    //Pages
    .component(
      'EllevioWebOverviewPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/OverviewPage.vue'))
    )
    .component(
      'EllevioWebElectricityConsumptionPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/ElectricityConsumptionPage.vue'))
    )
    .component(
      'EllevioWebElectricityDeliverySitePage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/ElectricityDeliverySitePage.vue'))
    )
    .component(
      'EllevioWebInvoicePage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/InvoicePage.vue'))
    )
    .component(
      'EllevioWebPowerOutageNotificationsPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/PowerOutageNotificationsPage.vue'))
    )
    .component(
      'EllevioWebAgreementsPricesPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/AgreementsPricesPage.vue'))
    )
    .component(
      'EllevioWebMyDetailsPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/MyDetailsPage.vue'))
    )
    .component(
      'EllevioWebAccountSettingsPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/AccountSettingsPage.vue'))
    )
    .component(
      'EllevioWebContactDetailsPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/ContactDetailsPage.vue'))
    )
    .component(
      'EllevioWebCustomerServiceAdminPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/CustomerServiceAdminPage.vue'))
    )
    .component(
      'EllevioWebElectricityEffectTariffPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/ElectricityEffectTariffPage.vue'))
    )
    .component(
      'EllevioWebElectricityPriceSupportPage',
      defineAsyncComponent(() => import('./stories/my-pages/pages/ElectricityPriceSupportPage.vue'))
    )
    //Components in "block-slot"
    .component(
      'EllevioWebAccordionItem',
      defineAsyncComponent(() => import('./stories/web/vue-components/AccordionItem.vue'))
    )
    .component(
      'EllevioWebFeedbackForm',
      defineAsyncComponent(() => import('./stories/web/vue-components/FeedbackForm.vue'))
    )
    .component(
      'EllevioWebOverviewInfo',
      defineAsyncComponent(() => import('./stories/my-pages/vue-components/OverviewInfo.vue'))
    )
    .mount('.vue-my-pages')
}

//The last section in main.js
/*
if (document.querySelector('#scrollToOnLoad')) {
  document.querySelector('#scrollToOnLoad').scrollIntoView();
}
*/
//The last section in main.js
